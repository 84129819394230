import withTranslation from 'next-translate/withTranslation';
import React, {
    ChangeEventHandler,
    FocusEventHandler,
    useEffect,
    useState,
} from 'react';
import {styled} from 'styled-components';
import {withAnalytics} from '../modules/analytics/analytics';
import {mediaQueries, StyledProps, ThemedProps} from '../modules/common';
import {I18n} from '../modules/util/i18n/i18n-interface';
import {useValidation} from '../modules/util/use-validation';
import {getConnectHomeLandingURLLink} from '../modules/util/utilities/get-connect-url-link';
import {Validators} from '../modules/util/validators';
import {H1} from './headings';
import {LoaderButton} from './loader-button';
import {TextAdornment, TextField} from './text-field';
import {AppScoreWidget} from './app-score-widget';
import {getInitialValueForRechargeInputFromURL} from '../modules/util/utilities/get-initial-value-for-recharge-input-from-url';
import {HeaderNoise, GradientType} from './header-noise';
import {
    typographyDisplay2Styles,
    typographyHeading1Styles,
    typographyTitleBodyStyles,
} from '../modules/common/styles';
import ReviewsIoRatingBarWidget from './reviews-io/reviews-io-rating-bar-widget';
import {NavWrapper} from './nav/nav-wrapper';
import {PersonData} from '../modules/util/utilities/get-user-data';
import {getLoginUrl} from '../modules/util/utilities/get-login-url';
import {Analytics} from '../lib/analytics/analytics';
import {USCarriers} from './us-carriers';
import {SERVICES} from '../modules/util/constants';
import {Wrapper} from './home-wrapper';

interface HomeHeaderProps extends StyledProps {
    i18n: I18n;
    analytics: Analytics;
    title: string;
    labelText: string;
    buttonText: string;
    personData: PersonData;
    countryPrefix: number;
    mobilePrefixes: number[];
    cellphoneNumberLength: number;
}

const TitleSection = styled.div`
    max-width: 552px;
    margin-bottom: ${({theme}): string => theme.spacing.xxxxl};

    ${mediaQueries.lessThan('small')`
        margin-top: 3.5rem;
        margin-bottom: ${({theme}: ThemedProps): string => theme.spacing.xl};
    `}
`;

const StyledH1 = styled(H1)`
    ${typographyDisplay2Styles}
    text-align: left;
    margin: 0;
    margin-bottom: ${({theme}): string => theme.spacing.m};

    ${mediaQueries.lessThan('small')`         
        ${typographyHeading1Styles}
        text-align: center;
        
        margin-bottom: ${({theme}: ThemedProps): string => theme.spacing.l};
    `}
`;

const Label = styled.div`
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${({theme}) => theme.colors.ballena};

    margin-bottom: ${({theme}): string => theme.spacing.xs};
`;

const Div = styled.div`
    display: flex;
    justify-content: space-between;

    ${mediaQueries.lessThan('small')`         
        flex-direction: column;   
        margin-bottom: 0;
    `}
`;

export const SendButton = styled(LoaderButton)`
    ${typographyTitleBodyStyles}
    color: ${({theme}): string => theme.colors.arroz};

    min-width: 125px;
    max-height: 48px;

    ${mediaQueries.lessThan('small')`      
        margin-top: ${({theme}: ThemedProps): string => theme.spacing.m};
    `}
`;

const Container = styled.div`
    position: relative;
    height: auto;
    margin-bottom: 68px;
    display: flex;
    justify-content: space-between;

    > div:first-of-type {
        padding-top: 10rem;
    }

    ${mediaQueries.lessThan('huge')`
        justify-content: center;
    `};

    ${mediaQueries.lessThan('small')`         
        padding: 48px 24px 0;
        margin-bottom: 3rem;
        > div:first-of-type {
            padding-top: 1rem;
        }
    `}
`;

const Form = styled.div`
    display: flex;

    ${mediaQueries.lessThan('small')`         
       flex-direction: column;
    `}
`;

const StyledTextField = styled(TextField)`
    width: 285px;
    margin-right: 8px;

    ${mediaQueries.lessThan('small')`         
        width: unset;
        margin-right: 0;
    `}
`;

const Spacer = styled.div`
    margin-top: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    ${mediaQueries.greaterThan('small')`
        padding: 1.5rem 0;
    `}

    ${mediaQueries.lessThan('small')`
        margin-top: 40px;
        padding: 1rem 0;
    `}
`;

const RatingBarWidget = styled(ReviewsIoRatingBarWidget)`
    position: relative;
`;

const StyledNavWrapper = styled.div`
    ${mediaQueries.lessThan('small')`
        margin-left: 4px;
        position: relative;
    `}
`;

const StyledRatingBarWidget = styled(RatingBarWidget)`
    padding-left: 24px;
    padding-right: 24px;
`;

const UnstyledHeader = (props: HomeHeaderProps): JSX.Element => {
    const {
        className,
        title,
        labelText,
        buttonText,
        personData,
        countryPrefix,
        mobilePrefixes,
        cellphoneNumberLength,
    } = props;
    const [loginUrl, setLoginUrl] = useState('');

    const loadingGiftUrl =
        process.env.NEXT_PUBLIC_ASSETS_URL +
        '/v2/primary-loading-spinner-v2.svg';

    const {t, lang} = props.i18n;

    const [value, setValue] = useState('');

    const isLoggedIn = !!personData?.isLoggedIn;
    const notLoggedInProps = {
        loginUrl: loginUrl,
        i18n: props.i18n,
    };

    useEffect(() => {
        setLoginUrl(getLoginUrl(document.location.href));
        setValue(getInitialValueForRechargeInputFromURL(window.location.href));
    }, []);

    function formatListString(list: number[], conjunction: string): string {
        if (!list || list.length === 0) {
            return '';
        }

        if (list.length === 1) {
            return `${list[0]}`;
        }

        return `${list.slice(0, -1).join(', ')} ${conjunction} ${
            list[list.length - 1]
        }`;
    }

    const validators = [
        Validators.required(t('validation:phone-number-required')),
        Validators.phoneNumberLength(
            t('validation:phone-number-too-short'),
            t('validation:phone-number-too-long'),
            cellphoneNumberLength,
            cellphoneNumberLength
        ),
    ];
    if (mobilePrefixes)
        validators.push(
            Validators.phoneNumberStartsWithList(
                `${t(
                    'validation:phone-number-first-digit-list'
                )} ${formatListString(mobilePrefixes, t('common:or'))}`,
                mobilePrefixes
            )
        );

    const [validate, error, setError] = useValidation(...validators);

    const handleSubmit = () => {
        const err = validate(value);
        if (err) {
            return true;
        }

        const recipient = {
            phone_number_prefix: `${countryPrefix}`,
            phone_number_suffix: `${value}`,
        };

        window.location.href = getConnectHomeLandingURLLink(
            window.location.href,
            isLoggedIn,
            recipient,
            SERVICES.PHONE
        );
    };

    const handleFocus: FocusEventHandler<HTMLInputElement> = () => {
        if (error.error) {
            setError({error: false, errorText: ''});
        }
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue(`${event.target.value}`);
    };

    let nav = <NavWrapper isLoggedIn={false} navBarProps={notLoggedInProps} />;

    if (isLoggedIn) {
        const personDetails = personData.personDetails;

        const loggedInProps = {
            personDetails: personDetails,
            longShadow: false,
            i18n: props.i18n,
            analytics: props.analytics,
        };

        nav = <NavWrapper isLoggedIn={true} navBarProps={loggedInProps} />;
    }

    return (
        <header className={className}>
            <HeaderNoise gradient={GradientType.TeloaHomeGradient} />
            <StyledNavWrapper>{nav}</StyledNavWrapper>
            <Wrapper>
                <Container>
                    <div>
                        <TitleSection>
                            <StyledH1>{title}</StyledH1>
                        </TitleSection>
                        <Label>{labelText}</Label>
                        <Div>
                            <Form>
                                <StyledTextField
                                    inputType={'number'}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    error={error.error}
                                    {...error}
                                    value={value}
                                    {...{
                                        startAdornment: (
                                            <TextAdornment>
                                                {`+${countryPrefix}`}
                                            </TextAdornment>
                                        ),
                                    }}
                                    data-testid="contact-input"
                                />
                                <SendButton
                                    onClickHandler={handleSubmit}
                                    data-testid="send-button"
                                    loadingGifUrl={loadingGiftUrl}
                                >
                                    {buttonText}
                                </SendButton>
                            </Form>
                        </Div>
                    </div>
                </Container>
            </Wrapper>
            <USCarriers />
            <Spacer>
                {process.env.NEXT_PUBLIC_MOBILE_APP_URL && (
                    <AppScoreWidget appleScore={4.9} playScore={4.8} />
                )}
                <StyledRatingBarWidget
                    lang={lang}
                    widgetId="ZeUFq9i5D3PNmRXn"
                    storeName="teloa.com"
                />
            </Spacer>
        </header>
    );
};

const StyledHomeHeader = styled(UnstyledHeader)`
    position: relative;
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const HomeHeader = withAnalytics(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    withTranslation(StyledHomeHeader)
);

export {HomeHeader};
